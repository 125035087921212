import CurrencyFormatter from "@components/currencyFormatter";
import toCurrency from "@components/helpers/toCurrency";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";

const PreviewInformation = ({
  //** Promo Information */
  namaPromo,
  kodePromo,
  tipePromo,
  formStartDate,
  formEndDate,

  //** Customer */
  isCustomer,
  customerData,
  selectedCustomer,
  isSales,
  salesData,
  selectedSales,
  isWLarea,
  wlAreaData,
  selectedWlArea,
  isUser,
  userData,
  selectedUser,

  //** Product */
  isManufaktur,
  manufakturData,
  selectedManufactur,
  isKategori,
  kategoriData,
  selectedKategori,
  isSKU,
  skuData,
  selectedSKU,
  superTierIsActive,
  supertier,

  // Benefit
  benefitData,
  benefitSuperTier,
  benefitKelipatan,
  // button
  setExitDialog,
  onSubmit,
}) => {
  const [ketentuanData, setKetentuanData] = useState([]);

  const convertData = () => {
    const data = [];

    // Pastikan selectedSKU tidak undefined atau null
    if (!selectedSKU || !Array.isArray(selectedSKU)) {
      console.error("selectedSKU is not defined or not an array");
      return; // Kembalikan fungsi jika selectedSKU tidak valid
    }

    // Iterasi melalui setiap produk di selectedSKU
    selectedSKU.forEach((product) => {
      // Pastikan bahwa `product.data` ada dan merupakan array
      if (product.data && Array.isArray(product.data)) {
        product.data.forEach((item, index) => {
          // Memastikan bahwa item aktif dan type_condition adalah 'tiering'
          if (item.is_active === 1 && item.type_condition === "tiering") {
            // Tier berdasarkan urutan data (index + 1)
            const tierName = `Tier ${index + 1}`;

            // Cek apakah tier sudah ada di data
            let tier = data.find((tier) => tier.tierName === tierName);

            // Jika tier belum ada, buat object baru
            if (!tier) {
              tier = {
                tierName,
                data: [],
              };
              data.push(tier);
            }

            // Tambahkan produk ke dalam tier tersebut
            tier.data.push({
              id: product.id,
              name: product.name,
              value: item.value,
              type: item.type,
            });
          }
        });
      } else {
        console.error("Product data is not defined or not an array", product);
      }
    });

    return setKetentuanData(data);
  };

  const findBenefit = (val) => {
    if (!val) return "";
    return dropdownBenefit.map((benefit) => {
      if (benefit.val == val) return benefit.name;
    });
  };

  useEffect(() => {
    convertData();
  }, [selectedSKU]);

  return (
    <Col className="mx-xl-auto my-sm-2" sm={12} lg={4}>
      <div className="bg-white shadow-sm mx-auto p-3 border rounded-lg">
        <h5>Preview Informasi</h5>
        <hr />
        <div>
          <span className="font-weight-bold h6">Informasi Promo</span>
          <div className="d-flex flex-row my-2">
            <div className="mr-auto w-25">
              <p className="text-secondary">Nama Promo</p>
              <p>{namaPromo || "-"}</p>
            </div>
            <div className="mr-auto w-25">
              <p className="text-secondary">Kode Promo</p>
              <p>{kodePromo || "-"}</p>
            </div>
          </div>
          <div className="d-flex flex-row my-2">
            <div className="mr-auto w-25">
              <p className="text-secondary">Tipe Promo</p>
              <p>{tipePromo || "-"}</p>
            </div>
            <div className="mr-auto w-25">
              <p className="text-secondary">Periode</p>
              <p>{`${
                formStartDate
                  ? moment(formStartDate).format("DD/MM/YYYY")
                  : "DD/MM/YYYY"
              } - ${
                formEndDate
                  ? moment(formEndDate).format("DD/MM/YYYY")
                  : "DD/MM/YYYY"
              }`}</p>
            </div>
          </div>
        </div>
        <hr />
        <div>
          <p className="font-weight-bold h6">Customer</p>
          <span>
            {!isCustomer ? null : (
              <span>
                Customer (
                {customerData?.value === "all"
                  ? "All"
                  : selectedCustomer.map((item, index) => (
                      <span key={item.id}>{`${item.name}${
                        index + 1 !== selectedCustomer.length ? ", " : ""
                      }`}</span>
                    ))}
                ) {isSales || isWLarea || isUser ? " dan " : ""}
              </span>
            )}
            {!isSales ? null : (
              <span>
                Sales (
                {salesData?.value === "all"
                  ? "All"
                  : selectedSales.map((item, index) => (
                      <span key={item.id}>{`${item.name}${
                        index + 1 !== selectedSales.length ? ", " : ""
                      }`}</span>
                    ))}
                ) {isWLarea || isUser ? " dan " : ""}
              </span>
            )}
            {!isWLarea ? null : (
              <span>
                WL Area (
                {wlAreaData?.value === "all"
                  ? "All"
                  : selectedWlArea.map((item, index) => (
                      <span key={item.id}>{`${item.name}${
                        index + 1 !== selectedWlArea.length ? ", " : ""
                      }`}</span>
                    ))}
                ) {isUser ? " dan " : ""}
              </span>
            )}
            {!isUser ? null : (
              <span>
                Jenis Customer (
                {userData?.value === "all"
                  ? "All"
                  : selectedUser.map((item, index) => (
                      <span key={item.id}>{`${item.name}${
                        index + 1 !== selectedUser.length ? ", " : ""
                      }`}</span>
                    ))}
                )
              </span>
            )}
          </span>
        </div>
        <hr />
        <div>
          <p className="font-weight-bold h6">Product</p>
          <span>
            {!isManufaktur ? null : (
              <span>
                Manufaktur (
                {manufakturData?.value === "all"
                  ? "All"
                  : selectedManufactur.map((item, index) => (
                      <span key={item.id}>{`${item.name}${
                        index + 1 !== selectedManufactur.length ? ", " : ""
                      }`}</span>
                    ))}
                ) {isKategori || isSKU ? " dan " : ""}
              </span>
            )}
            {!isKategori ? null : (
              <span>
                Kategori (
                {kategoriData?.value === "all"
                  ? "All"
                  : selectedKategori.map((item, index) => (
                      <span key={item.id}>{`${item.name}${
                        index + 1 !== selectedKategori.length ? ", " : ""
                      }`}</span>
                    ))}
                ) {isSKU ? " dan " : ""}
              </span>
            )}
            {!isSKU ? null : (
              <span>
                SKU (
                {skuData?.value === "all"
                  ? "All"
                  : selectedSKU.map((item, index) => (
                      <span key={item.id}>{`${item.name}${
                        index + 1 !== selectedSKU.length ? ", " : ""
                      }`}</span>
                    ))}
                )
              </span>
            )}
          </span>
        </div>
        <hr />

        <div>
          <span className="font-weight-bold h6">Ketentuan</span>
          {ketentuanData.map((tier, tierIdx) => (
            <div className="bg-light my-2 p-2 rounded-lg text-dark">
              <div key={tierIdx + 1}>
                <span className="font-weight-bold">{tier.tierName}</span>
                {tier.data.map((product, idxProduct) => (
                  <div
                    key={idxProduct + 1}
                    className="d-flex flex-row justify-content-between"
                  >
                    <span>{product.name}</span>
                    {product.type === "value" ? (
                      <span>{toCurrency(product.value)}</span>
                    ) : (
                      <span>x{product.value}</span>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
          {!supertier.length || !superTierIsActive ? null : (
            <div className="bg-light my-2 p-2 rounded-lg text-dark">
              <span className="font-weight-bold">SUPER TIER</span>
              {supertier.map((tier, tierIdx) => (
                <div
                  key={tierIdx + 1}
                  className="d-flex flex-row justify-content-between"
                >
                  <span>{tier.name}</span>
                  <span>x{tier.value}</span>
                </div>
              ))}
            </div>
          )}
        </div>
        <hr />

        <div>
          <span className="font-weight-bold h6">Benefit</span>
          {benefitData.map((benefit, index) => (
            <div
              key={index + 1}
              className="bg-light my-2 p-2 rounded-lg text-dark"
            >
              <span className="font-weight-bold">{benefit.name}</span>
              <div className="d-flex flex-row justify-content-between">
                <span>Tipe Benefit: </span>
                <span>{findBenefit(benefit?.type_benefit)}</span>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <span>Potongan: </span>
                <span>{benefit?.type}</span>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <span>Benefit: </span>
                {benefit.type === "amount" ? (
                  <span>{toCurrency(benefit?.value ?? 0)}</span>
                ) : benefit.type_benefit === "free-gift" &&
                  benefit.type === "instock" ? (
                  <span style={{ textAlign: "right", width: "80%" }}>
                    {benefit?.selectedProduct?.length &&
                      benefit.selectedProduct.map((item, index) => (
                        <span key={item.id}>{`${item.name}${
                          index + 1 !== benefit.selectedProduct.length
                            ? ", "
                            : ""
                        }`}</span>
                      ))}
                  </span>
                ) : (
                  <span>{benefit?.value}</span>
                )}
              </div>
              {benefit.type === "amount" ||
              benefit.type_benefit === "free-gift" ? null : (
                <div className="d-flex flex-row justify-content-between">
                  <span>Benefit Maksimal: </span>
                  <span>{toCurrency(benefit?.maks_benefit ?? 0)}</span>
                </div>
              )}
            </div>
          ))}

          {!benefitSuperTier || !superTierIsActive ? null : (
            <div className="bg-light my-2 p-2 rounded-lg text-dark">
              <span className="font-weight-bold">SUPER TIER</span>
              <div className="d-flex flex-row justify-content-between">
                <span>Tipe Benefit: </span>
                <span>{findBenefit(benefitSuperTier?.type_benefit)}</span>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <span>Potongan: </span>
                <span>{benefitSuperTier?.type}</span>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <span>Benefit: </span>
                {benefitSuperTier.type === "amount" ? (
                  <span>{toCurrency(benefitSuperTier?.value ?? 0)}</span>
                ) : benefitSuperTier.type_benefit === "free-gift" &&
                  benefitSuperTier.type === "instock" ? (
                  <span style={{ textAlign: "right", width: "80%" }}>
                    {benefitSuperTier?.selectedProduct?.length &&
                      benefitSuperTier.selectedProduct.map((item, index) => (
                        <span key={item.id}>{`${item.name}${
                          index + 1 !== benefitSuperTier.selectedProduct.length
                            ? ", "
                            : ""
                        }`}</span>
                      ))}
                  </span>
                ) : (
                  <span>{benefitSuperTier?.value}</span>
                )}
              </div>
              {benefitSuperTier.type === "amount" ||
              benefitSuperTier.type_benefit === "free-gift" ? null : (
                <div className="d-flex flex-row justify-content-between">
                  <span>Benefit Maksimal: </span>
                  <span>{toCurrency(benefitSuperTier?.maks_benefit ?? 0)}</span>
                </div>
              )}
            </div>
          )}
          {!benefitKelipatan ? null : (
            <div className="bg-light my-2 p-2 rounded-lg text-dark">
              <div className="d-flex flex-row justify-content-between">
                <span>Tipe Benefit: </span>
                <span>{findBenefit(benefitKelipatan?.type_benefit)}</span>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <span>Potongan: </span>
                <span>{benefitKelipatan?.type}</span>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <span>Benefit: </span>
                {benefitKelipatan.type === "amount" ? (
                  <span>{toCurrency(benefitKelipatan?.value ?? 0)}</span>
                ) : benefitKelipatan.type_benefit === "free-gift" &&
                  benefitKelipatan.type === "instock" ? (
                  <span style={{ textAlign: "right", width: "80%" }}>
                    {benefitKelipatan?.selectedProduct?.length &&
                      benefitKelipatan.selectedProduct.map((item, index) => (
                        <span key={item.id}>{`${item.name}${
                          index + 1 !== benefitKelipatan.selectedProduct.length
                            ? ", "
                            : ""
                        }`}</span>
                      ))}
                  </span>
                ) : (
                  <span>{benefitKelipatan?.value}</span>
                )}
              </div>
              {benefitKelipatan.type === "amount" ||
              benefitKelipatan.type_benefit === "free-gift" ? null : (
                <div className="d-flex flex-row justify-content-between">
                  <span>Benefit Maksimal: </span>
                  <span>{toCurrency(benefitKelipatan?.maks_benefit ?? 0)}</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="d-flex flex-row justify-content-end my-3">
        <button
          onClick={() => setExitDialog(true)}
          className="bg-white mr-2 px-3 py-2 border-light rounded"
        >
          Batal
        </button>
        <button
          onClick={onSubmit}
          className="mr-2 px-3 py-2 rounded btn-primary"
        >
          Simpan
        </button>
      </div>
    </Col>
  );
};

const dropdownBenefit = [
  { name: "Potongan Harga", val: "potongan-harga" },
  { name: "Free Gift", val: "free-gift" },
  { name: "Voucher", val: "voucher" },
  { name: "CN", val: "cn" },
];

export default PreviewInformation;
