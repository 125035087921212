import { useState, Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

//component
import { Container, Row, Col } from "reactstrap";
import Breadcrumb from "@layout/breadcrumb";
import AddKondisiCustomer from "./addKondisiCustomer";
import AddKondisiProduct from "./addKondisiProduct";
import BenefitPromo from "../component/BenefitPromo";
import PreviewInformation from "./PreviewInformation";
import PromoInformation from "./PromoInformation";
import { ModalDialog } from "@components/modal/ModalDialog";
import ModalContent from "@components/modal/ModalContent";
import moment from "moment";
import { useParams } from "react-router-dom";
import { getDetailPromoNew, updateNewPromo } from "@action/promoAction";

const NewPromotionEdit = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [namaPromo, setNamaPromo] = useState("");
  const [kodePromo, setKodePromo] = useState("");
  const [kuotaPromo, setKuotaPromo] = useState("");
  const [tipePromo, setTipePromo] = useState("other");
  const [image, setImage] = useState(null);
  const [formStartDate, setStartDate] = useState("");
  const [formEndDate, setEndDate] = useState("");
  const [deskripsi, setDeskripsi] = useState("");
  const [benefitData, setBenefitData] = useState([]);
  const [benefitSuperTier, setBenefitSuperTier] = useState(null);
  const [benefitKelipatan, setBenefitKelipatan] = useState(null);
  const [exitDialog, setExitDialog] = useState(false);
  const [idBenefit, setIdBenefit] = useState([]);
  const [idBenefitST, setIdBenefitST] = useState([]);
  // const [idBenefitKelipatan, setIdBenefitKelipatan] = useState([]);

  //** -------- Product -------- */
  const [productOn, setProductOn] = useState(true);
  const [productCondition, setProductCondition] = useState("and");
  const [productConditionKC, setProductConditionKC] = useState("and");
  const [isAddKondisiProduct, setAddKondisiProduct] = useState([]);
  //manufactur
  const [isManufaktur, setManufaktur] = useState(false);
  const [manufakturData, setManufakturData] = useState({
    value: "",
    type: "manufactur",
  });
  const [selectedManufactur, setSelectedManufactur] = useState([]);
  // Kategori
  const [isKategori, setKategori] = useState(false);
  const [kategoriData, setKategoriData] = useState({
    value: "",
    type: "kategori",
  });
  const [selectedKategori, setSelectedKategori] = useState([]);
  //SKU
  const [isSKU, setSKU] = useState(false);
  const [skuData, setSkuData] = useState({
    value: "",
    type: "sku",
    group: "kelipatan",
  });
  const [selectedSKU, setSelectedSKU] = useState([]);
  // Supertier
  const [supertier, setSupertier] = useState({ is_active: 0, data: [] });
  const [idProduct, setIdProduct] = useState({
    id: "",
    new_promo_id: "",
  });
  const [idProductLvl1, setIdProductLvl1] = useState({
    id: "",
    promo_condition_id: "",
  });
  //** -------- End Product -------- */

  //** -------- Customer -------- */
  const [customerOn, setCustomerOn] = useState(true);
  const [customerCondition, setCustomerCondition] = useState("and");
  const [customerConditionKC, setCustomerConditionKC] = useState("and");
  const [isAddKondisiCustomer, setAddKondisiCustomer] = useState([]);
  //customer
  const [isCustomer, setCustomer] = useState(false);
  const [customerData, setCustomerData] = useState({
    value: "",
    type: "customers",
  });
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  //sales
  const [isSales, setSales] = useState(false);
  const [salesData, setSalesData] = useState({
    value: "",
    type: "sales",
  });
  const [selectedSales, setSelectedSales] = useState([]);
  //WL area
  const [isWLarea, setWLarea] = useState(false);
  const [wlAreaData, setWlAreaData] = useState({
    value: "",
    type: "wl_area",
  });
  const [selectedWlArea, setSelectedWlArea] = useState([]);
  //Jenis Customer
  const [isUser, setUser] = useState(false);
  const [userData, setUserData] = useState({
    value: "",
    type: "jenis_customer",
  });
  const [selectedUser, setSelectedUser] = useState([]);
  const [idCustomer, setIdCustomer] = useState({
    id: "",
    new_promo_id: "",
  });
  const [idCustomerLvl1, setIdCustomerLvl1] = useState({
    id: "",
    promo_condition_id: "",
  });
  //** -------- End Customer -------- */

  const onSubmit = async () => {
    const customerLevel1 = {
      name: 1,
      condition: customerCondition,
      ...idCustomerLvl1,
      data: [
        {
          ...customerData,
          items: filterDataId(selectedCustomer),
        },
        {
          ...salesData,
          items: filterDataId(selectedSales),
        },
        {
          ...wlAreaData,
          items: filterDataId(selectedWlArea),
        },
        {
          ...userData,
          items: filterDataId(selectedUser),
        },
      ],
    };

    const dataCustomerLvl2 = isAddKondisiCustomer.map((item) => {
      return {
        ...item,
        name: item.name + 1,
        data: item.data.length
          ? item.data.map((x) => {
              return {
                ...x,
                items: filterDataId(x.items),
              };
            })
          : [],
      };
    });

    const customerLevel2 = dataCustomerLvl2;

    const superTierData = filterDataQty(supertier.data);
    const productLevel1 = {
      name: 1,
      condition: productCondition,
      ...idProductLvl1,
      data: [
        {
          ...manufakturData,
          items: filterDataId(selectedManufactur),
        },
        {
          ...kategoriData,
          items: filterDataId(selectedKategori),
        },
        {
          ...skuData,
          items: filterDataSku(selectedSKU),
          super_tier: supertier.is_active ? [...superTierData] : [],
        },
      ],
    };

    const dataProductLvl2 = isAddKondisiProduct.map((item) => {
      return {
        ...item,
        data: item?.data?.length
          ? item.data.map((x) => {
              return {
                ...x,
                items:
                  x.type === "sku"
                    ? filterDataSku(x.items)
                    : filterDataId(x.items),
              };
            })
          : [],
      };
    });

    const productLevel2 = dataProductLvl2;

    const benefitST = [{ ...benefitSuperTier, is_super_tier: 1 }];
    let benefit = benefitSuperTier
      ? [
          ...mergeDataBenefit(idBenefit, benefitData),
          ...mergeDataBenefit(
            [{ ...idBenefitST, is_super_tier: 1 }],
            benefitST
          ),
        ]
      : mergeDataBenefit(idBenefit, benefitData);
    benefit =
      !benefitData?.length && benefitKelipatan
        ? [...benefit, mergeDataBenefit(idBenefit, benefitKelipatan)]
        : benefit;
    benefit = filterBenefit(benefit);

    const customerLvlData = {
      data: customerOn
        ? [deleteEmptyData(customerLevel1), ...customerLevel2]
        : [],
      condition: customerCondition,
      type: "customers",
      is_active: customerOn,
      ...idCustomer,
    };
    const productLvlData = {
      data: productOn ? [deleteEmptyData(productLevel1), ...productLevel2] : [],
      condition: productCondition,
      type: "products",
      is_active: productOn,
      ...idProduct,
    };

    const data = {
      data: [customerLvlData, productLvlData],
      benefit,
    };

    const params = {
      nama: namaPromo,
      kode: kodePromo,
      kuota: kuotaPromo,
      banner: image,
      deskripsi: deskripsi,
      start_at: formStartDate ? moment(formStartDate).format("YYYY-MM-DD") : "",
      end_at: formEndDate ? moment(formEndDate).format("YYYY-MM-DD") : "",
      items_condition: JSON.stringify(data),
    };
    console.log("onotrak data", data);
    console.log("onotrak params", params);

    const formData = new FormData();
    formData.append("nama", namaPromo);
    formData.append("kode", kodePromo);
    formData.append("kuota", kuotaPromo);
    formData.append("banner", image?.file ?? "");
    formData.append("deskripsi", deskripsi);
    formData.append(
      "start_at",
      formStartDate ? moment(formStartDate).format("YYYY-MM-DD") : ""
    );
    formData.append(
      "end_at",
      formEndDate ? moment(formEndDate).format("YYYY-MM-DD") : ""
    );
    formData.append("items_condition", JSON.stringify(data));
    formData.append("id", id);

    dispatch(updateNewPromo(formData)).then((res) => {
      console.log("onotrak res edit promo", res);
      if (res.status !== 200) return false;
      history.push("/dashboard/promotion/");
    });
  };

  const filterBenefit = (dataArray) => {
    if (!dataArray?.length) return [];
    return dataArray.map((obj) => ({
      type_benefit: obj?.type_benefit || "",
      type: obj?.type || "",
      value: obj?.value || "",
      maks_benefit: obj?.maks_benefit || "",
      is_super_tier: obj?.is_super_tier || "0",
      id: obj?.id,
      new_promo_id: obj?.new_promo_id,
      is_deleted: obj?.is_deleted || false,
    }));
  };
  const filterDataId = (dataArray) => {
    if (!dataArray?.length) return [];
    return dataArray.map((obj) => {
      return { value: obj.id.toString() };
    });
  };
  const filterDataQty = (dataArray) => {
    if (!dataArray?.length) return [];
    return dataArray.map((obj) => ({
      qty: obj.value || "",
    }));
  };
  const filterDataAddCondition = (dataArray) => {
    if (!dataArray?.length) return [];
    return dataArray.reduce((acc, curr) => {
      if (Array.isArray(curr.data)) {
        return acc.concat(curr.data);
      }
      return acc;
    }, []);
  };
  const filterDataAddConditionLvl2 = (dataArray) => {
    if (!dataArray?.length) return [];
    return dataArray.map((obj, idx) => ({
      name: idx + 2,
      condition: obj.condition || "and",
      data: obj.data,
    }));
  };
  const filterDataSku = (dataArray) => {
    if (!dataArray?.length) return [];
    return dataArray.map((obj) => ({
      relation_id: obj.id || "",
      data: obj?.data?.length
        ? obj.data.map((x) => {
            return {
              type: x.type,
              type_condition: x.type_condition || x.group,
              value: x.value,
              is_active: x.is_active,
            };
          })
        : [],
    }));
  };

  const deleteEmptyData = (data) => {
    return {
      ...data,
      data: data.data.filter((item) => item.value !== ""),
    };
  };

  const addBenefitData = () => {
    setBenefitData([
      {
        type_benefit: "potongan-harga",
        type: "percentage",
        value: "0",
        maks_benefit: "0",
      },
    ]);
  };

  const deleteDefaultBenefitData = () => {
    const data = benefitData.filter((item) => item.hasOwnProperty("name"));
    setBenefitData(data);
  };

  useEffect(() => {
    if (
      (isCustomer ||
        isSales ||
        isWLarea ||
        isUser ||
        isManufaktur ||
        isKategori) &&
      !isSKU
    ) {
      addBenefitData();
    } else {
      deleteDefaultBenefitData();
    }
  }, [isCustomer, isSales, isWLarea, isUser, isManufaktur, isKategori, isSKU]);

  useEffect(() => {
    dispatch(getDetailPromoNew(id)).then((res) => {
      console.log("onotrak res detail promo", res);
      if (res?.status !== 200) return false;
      if (!res?.data?.data) return false;
      functionChangeResponse(res.data.data);
    });
  }, [id]);

  const functionChangeResponse = (promoData) => {
    if (promoData) {
      setNamaPromo(promoData.nama || "");
      setKodePromo(promoData.kode || "");
      setKuotaPromo(promoData.kuota || "");
      setImage({ src: promoData.banner } || null);
      setDeskripsi(promoData.deskripsi || "");
      setStartDate(
        promoData.start_at
          ? moment(promoData.start_at).format("YYYY-MM-DD")
          : ""
      );
      setEndDate(
        promoData.end_at ? moment(promoData.end_at).format("YYYY-MM-DD") : ""
      );

      const itemsCondition = promoData.promo_condition;
      const customersData = itemsCondition.find(
        (item) => item.type === "customers"
      );
      const productData = itemsCondition.find(
        (item) => item.type === "products"
      );

      setCustomerOn(customersData.is_active ? true : false);
      setProductOn(productData.is_active ? true : false);

      setIdCustomer({
        id: customersData?.id,
        new_promo_id: customersData?.new_promo_id,
      });
      setIdProduct({
        id: productData?.id,
        new_promo_id: productData?.new_promo_id,
      });

      //** Mengupdate state untuk customers level 1 */
      const customersData1 = customersData?.promo_value.find(
        (item) => item.name === "1"
      );

      if (customersData1) {
        setIdCustomerLvl1({
          id: customersData1?.id,
          promo_condition_id: customersData1?.promo_condition_id,
        });
      }

      const customers = customersData1?.data.find(
        (item) => item.type === "customers"
      );
      const sales = customersData1?.data.find((item) => item.type === "sales");
      const wl_area = customersData1?.data.find(
        (item) => item.type === "wl_area"
      );
      const jenis_customer = customersData1?.data.find(
        (item) => item.type === "jenis_customer"
      );

      setCustomerCondition(customersData1?.condition);
      if (customers?.id) {
        setCustomerData({
          value: customers.value,
          type: "customers",
          id: customers.id,
          promo_condition_id: customers.promo_condition_id,
        });
        setSelectedCustomer(
          transformSelectedData(customers?.promo_value_detail) || []
        );
        setCustomer(true);
      }
      if (sales?.id) {
        setSalesData({
          value: sales.value,
          type: "sales",
          id: sales.id,
          promo_condition_id: sales.promo_condition_id,
        });
        setSelectedSales(
          transformSelectedData(sales?.promo_value_detail) || []
        );
        setSales(true);
      }
      if (wl_area?.id) {
        setWlAreaData({
          value: wl_area.value,
          type: "wl_area",
          id: wl_area.id,
          promo_condition_id: wl_area.promo_condition_id,
        });
        setSelectedWlArea(
          transformSelectedData(wl_area?.promo_value_detail) || []
        );
        setWLarea(true);
      }
      if (jenis_customer?.id) {
        setUserData({
          value: jenis_customer.value,
          type: "jenis_customer",
          id: jenis_customer.id,
          promo_condition_id: jenis_customer.promo_condition_id,
        });
        setSelectedUser(
          transformSelectedData(jenis_customer?.promo_value_detail) || []
        );
        setUser(true);
      }

      //** Mengupdate state untuk customers level 2 */
      const customersData2 = customersData?.promo_value.filter(
        (item) => parseInt(item.name) > 1
      );
      setCustomerConditionKC(customersData2?.condition);

      if (customersData2?.length) {
        const customerLevel2 = customersData2.map((x) => {
          return {
            ...x,
            name: parseInt(x.name) - 1,
            data: x.data?.length
              ? x.data.map((z) => {
                  return {
                    ...z,
                    items: transformSelectedData(z.promo_value_detail),
                  };
                })
              : [],
          };
        });
        setAddKondisiCustomer(customerLevel2);
      }

      //** Mengupdate state untuk product level 1 */
      const productsData1 = productData?.promo_value.find(
        (item) => item.name === "1"
      );

      if (productsData1) {
        setIdProductLvl1({
          id: productsData1?.id,
          promo_condition_id: productsData1?.promo_condition_id,
        });
      }

      const manufactur = productsData1.data.find(
        (item) => item.type === "manufactur"
      );
      const kategori = productsData1.data.find(
        (item) => item.type === "kategori"
      );
      const sku = productsData1.data.find((item) => item.type === "sku");

      setProductCondition(productsData1.condition);
      if (manufactur?.id) {
        setManufakturData({
          value: manufactur.value,
          type: "manufactur",
          id: manufactur.id,
          promo_condition_id: manufactur.promo_condition_id,
        });
        setSelectedManufactur(
          transformSelectedData(manufactur?.promo_value_detail)
        );
        setManufaktur(true);
      }
      if (kategori?.id) {
        setKategoriData({
          value: kategori.value,
          type: "kategori",
          id: kategori.id,
          promo_condition_id: kategori.promo_condition_id,
        });
        setSelectedKategori(
          transformSelectedData(kategori?.promo_value_detail)
        );
        setKategori(true);
      }
      if (sku?.id) {
        const group =
          sku?.promo_value_detail[0]?.promo_tier[0]?.type_condition || "";
        setSkuData({
          value: sku.value,
          type: "sku",
          group,
          id: sku.id,
          promo_condition_id: sku.promo_condition_id,
        });
        setSelectedSKU(transformSelectedDataSKU(sku?.promo_value_detail));
        setSKU(true);
      }

      //** Mengupdate state untuk product level 2 */
      const productData2 = productData?.promo_value.filter(
        (item) => parseInt(item.name) > 1
      );

      setProductConditionKC(productData2?.condition);

      if (productData2?.length) {
        const productLevel2 = productData2.map((x) => {
          return {
            ...x,
            name: parseInt(x.name) - 1,
            data: x.data?.length
              ? x.data.map((z) => {
                  return {
                    ...z,
                    items:
                      z.type === "sku"
                        ? transformSelectedDataSKU(z.promo_value_detail)
                        : transformSelectedData(z.promo_value_detail),
                  };
                })
              : [],
          };
        });
        setAddKondisiProduct(productLevel2);
      }

      //** Mengupdate state untuk benefit */
      const benefit = promoData?.benefit?.length
        ? promoData.benefit
            .map((item, index) => {
              if (!item.is_super_tier) {
                return {
                  maks_benefit: item.maks_benefit,
                  type: item.type,
                  type_benefit: item.type_benefit,
                  value: item.value,
                  name: `Tier ${index + 1}`,
                  id: item.id,
                  new_promo_id: item.new_promo_id,
                };
              }
            })
            .filter(Boolean)
        : [];

      setIdBenefit(benefit);
      setBenefitData(benefit);

      const benefitSupertier = promoData?.benefit?.length
        ? promoData.benefit.find((item) => item.is_super_tier)
        : null;

      if (benefitSupertier?.is_super_tier) {
        const benefitST = {
          maks_benefit: benefitSupertier.maks_benefit,
          type: benefitSupertier.type,
          type_benefit: benefitSupertier.type_benefit,
          value: benefitSupertier.value,
          id: benefitSupertier.id,
          new_promo_id: benefitSupertier.new_promo_id,
        };
        setIdBenefitST(benefitST);
        setBenefitSuperTier(benefitST);
      }

      //** Mengupdate state untuk supertier */
      const supertierData = promoData.super_tier?.length
        ? promoData.super_tier.map((item) => {
            return { name: `Tier ${item.tier}`, value: item.qty };
          })
        : [];
      if (supertierData.length) {
        setSupertier({ is_active: 1, data: supertierData });
      }
    }
  };

  const mergeDataBenefit = (arrayId, arrayData) => {
    const isNew = arrayId.length < arrayData.length;
    const isDelete = arrayId.length > arrayData.length;

    let result = [];
    if (!arrayData.length) return;
    const existingLength = arrayData.length;
    let deletedData = arrayId.slice(existingLength);

    result = arrayData.map((item, index) => {
      return {
        ...item,
        id: arrayId[index]?.id || "",
        new_promo_id: arrayId[index]?.new_promo_id || "",
      };
    });

    if (isDelete) {
      deletedData = deletedData.map((x) => {
        return {
          ...x,
          is_deleted: true,
        };
      });
      return [...result, ...deletedData];
    }
    return result;
  };

  const transformSelectedData = (data) => {
    if (!data?.length) return [];
    return data
      .map((item) => {
        if (item?.detail) {
          return {
            ...item.detail,
            name: item?.detail?.name || "-",
            id: item?.detail?.id || 0,
            promo_condition_id: item.promo_condition_id,
            idDetail: item.id,
          };
        }
      })
      .filter(Boolean);
  };
  const transformSelectedDataSKU = (data) => {
    if (!data?.length) return [];
    return data.map((item) => {
      if (item?.detail) {
        return {
          ...item.detail,
          name: item?.detail?.name || "-",
          id: item?.detail?.id || 0,
          data: item?.promo_tier?.length
            ? item.promo_tier.map((tier) => {
                return {
                  is_active: tier.is_active,
                  type: tier.type,
                  type_condition: tier.type_condition,
                  value: tier.value,
                };
              })
            : [],
        };
      }
      return { name: "-", id: 0 };
    });
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Promotion"
        title="Edit New Promotion"
        urlParent={"/dashboard/promotion"}
      />
      <Container fluid>
        <Row>
          <Col className="my-sm-2" sm={12} lg={8}>
            <PromoInformation
              namaPromo={namaPromo}
              setNamaPromo={setNamaPromo}
              kodePromo={kodePromo}
              setKodePromo={setKodePromo}
              kuotaPromo={kuotaPromo}
              setKuotaPromo={setKuotaPromo}
              image={image}
              setImage={setImage}
              deskripsi={deskripsi}
              setDeskripsi={setDeskripsi}
              tipePromo={tipePromo}
              setTipePromo={setTipePromo}
              formStartDate={formStartDate}
              setStartDate={setStartDate}
              formEndDate={formEndDate}
              setEndDate={setEndDate}
            />

            <div className="d-flex flex-column bg-white shadow-sm my-3 p-3 border rounded-lg">
              <h4>Kondisi</h4>
              <div className="p-2">
                <AddKondisiCustomer
                  customerOn={customerOn}
                  setCustomerOn={setCustomerOn}
                  customerCondition={customerCondition}
                  setCustomerCondition={setCustomerCondition}
                  isAddKondisi={isAddKondisiCustomer}
                  setAddKondisi={setAddKondisiCustomer}
                  isCustomer={isCustomer}
                  setCustomer={setCustomer}
                  customerData={customerData}
                  setCustomerData={setCustomerData}
                  selectedCustomer={selectedCustomer}
                  setSelectedCustomer={setSelectedCustomer}
                  isSales={isSales}
                  setSales={setSales}
                  salesData={salesData}
                  setSalesData={setSalesData}
                  selectedSales={selectedSales}
                  setSelectedSales={setSelectedSales}
                  isWLarea={isWLarea}
                  setWLarea={setWLarea}
                  wlAreaData={wlAreaData}
                  setWlAreaData={setWlAreaData}
                  selectedWlArea={selectedWlArea}
                  setSelectedWlArea={setSelectedWlArea}
                  isUser={isUser}
                  setUser={setUser}
                  userData={userData}
                  setUserData={setUserData}
                  selectedUser={selectedUser}
                  setSelectedUser={setSelectedUser}
                  customerConditionKC={customerConditionKC}
                  setCustomerConditionKC={setCustomerConditionKC}
                />
                <AddKondisiProduct
                  productOn={productOn}
                  setProductOn={setProductOn}
                  productCondition={productCondition}
                  setProductCondition={setProductCondition}
                  isAddKondisi={isAddKondisiProduct}
                  setAddKondisi={setAddKondisiProduct}
                  isManufaktur={isManufaktur}
                  setManufaktur={setManufaktur}
                  manufakturData={manufakturData}
                  setManufakturData={setManufakturData}
                  selectedManufactur={selectedManufactur}
                  setSelectedManufactur={setSelectedManufactur}
                  isKategori={isKategori}
                  setKategori={setKategori}
                  kategoriData={kategoriData}
                  setKategoriData={setKategoriData}
                  selectedKategori={selectedKategori}
                  setSelectedKategori={setSelectedKategori}
                  isSKU={isSKU}
                  setSKU={setSKU}
                  skuData={skuData}
                  setSkuData={setSkuData}
                  selectedSKU={selectedSKU}
                  setSelectedSKU={setSelectedSKU}
                  supertier={supertier}
                  setSupertier={setSupertier}
                  setBenefitData={setBenefitData}
                  setBenefitSuperTier={setBenefitSuperTier}
                  setBenefitKelipatan={setBenefitKelipatan}
                  productConditionKC={productConditionKC}
                  setProductConditionKC={setProductConditionKC}
                  isBenefit={
                    isCustomer ||
                    isSales ||
                    isWLarea ||
                    isUser ||
                    isManufaktur ||
                    isKategori
                  }
                />
              </div>
            </div>
            <BenefitPromo
              benefitData={benefitData}
              setBenefitData={setBenefitData}
              benefitSuperTier={benefitSuperTier}
              setBenefitSuperTier={setBenefitSuperTier}
              benefitKelipatan={benefitKelipatan}
              setBenefitKelipatan={setBenefitKelipatan}
              supertier={supertier}
            />
          </Col>
          <PreviewInformation
            //** Promo Information */
            namaPromo={namaPromo}
            kodePromo={kodePromo}
            tipePromo={tipePromo}
            formStartDate={formStartDate}
            formEndDate={formEndDate}
            //** End */

            //** Customer */
            isCustomer={isCustomer}
            customerData={customerData}
            selectedCustomer={selectedCustomer}
            isSales={isSales}
            salesData={salesData}
            selectedSales={selectedSales}
            isWLarea={isWLarea}
            wlAreaData={wlAreaData}
            selectedWlArea={selectedWlArea}
            isUser={isUser}
            userData={userData}
            selectedUser={selectedUser}
            //** End */

            //** Product */
            isManufaktur={isManufaktur}
            manufakturData={manufakturData}
            selectedManufactur={selectedManufactur}
            isKategori={isKategori}
            kategoriData={kategoriData}
            selectedKategori={selectedKategori}
            isSKU={isSKU}
            skuData={skuData}
            selectedSKU={selectedSKU}
            supertier={supertier?.data?.length ? supertier.data : []}
            superTierIsActive={supertier?.is_active}
            //** End */

            // Benefit
            benefitData={benefitData}
            benefitSuperTier={benefitSuperTier}
            benefitKelipatan={benefitKelipatan}
            // Button
            setExitDialog={setExitDialog}
            onSubmit={onSubmit}
          />
        </Row>

        <Fragment>
          <ModalDialog
            open={exitDialog}
            footer={false}
            title={"Unsaved Changes"}
            titleFontSize={18}
            backdropClose={true}
            onClose={() => {
              setExitDialog(false);
            }}
            maxWidth={384}
          >
            <ModalContent
              onClose={() => {
                setExitDialog(false);
              }}
              onSubmit={() => history.push("/dashboard/promotion/")}
              description={`You have unsaved changes. If you go back now, you will lose all your changes.`}
              descClose={"Cancel"}
              descSubmit={"Back To Promo List"}
            />
          </ModalDialog>
        </Fragment>
      </Container>
    </Fragment>
  );
};

export default NewPromotionEdit;
